/* You can add global styles to this file, and also import other style files */
// PrimeNG icon
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'assets/css/app/layout.scss';
@import 'quill/dist/quill.snow.css';

// dialog //
@media (min-width: 576px) {
  .dialog {
    width: 26rem;
  }
}
@media (max-width: 575px) {
  .dialog {
    width: 90vw;
  }
}
// ------------------- //

// dialog-line //
@media (min-width: 1200px) {
  .dialog-line {
    width: 50vw;
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .dialog-line {
    width: 70vw;
  }
}
@media (max-width: 767px) {
  .dialog-line {
    width: 90vw;
  }
}
// ------------------- //

// dialog chat //
@media (min-width: 576px) {
  .dialog-chat {
    width: 22rem;
  }
}
@media (max-width: 575px) {
  .dialog-chat {
    width: 90vw;
  }
}
// ------------------- //

// NOTE: QUILL EDITOR //
.iconToolbar {
  @apply block h-[20px] text-[#cecfd0] hover:text-[#06c];
}

#emoji-palette {
  top: -220px;
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  width: fit-content;
  height: 30px;
}
.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #cecfd0;
}
.ql-snow .ql-stroke {
  stroke: #cecfd0;
}
// Custom Font Family
.ql-container {
  font-family: var(--font-family);
}
.ql-container.ql-snow {
  border: unset;
}
.ql-toolbar.ql-snow {
  border: unset;
  border-top: 1px solid #e4e4e4;
}

@media (max-width: 382px) {
  .ql-toolbar.ql-snow {
    padding-right: 0;
  }
}
// ------------------ //

// NOTE: Google Maps //
// AutoComplete
.pac-container {
  z-index: 99999 !important;
  border: 1px solid #d9d9d9;
  box-shadow: unset !important;
}
// ----------------- //
